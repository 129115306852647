<template>
  <b-card
    title="记录消息"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            label-size="lg"
            label="设备ID"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              placeholder="请输入设备ID"
              v-model="queryParams.deviceId"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="3"
            label-size="lg"
            label="事件名称"
            label-for="input-lg"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="queryParams.eventTypes"
              :options="eventTypeOptions"
              class="w-100"
              :reduce="val => val.value"
              v-model="queryParams.eventTypes"
              placeholder="请选择事件名称"
            />
          </b-form-group>

        </b-col>
        <b-col md="6" class="tRight">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="searchButtonClick"
          >
            搜索
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:queryParams.pageSize
      }"
      theme="my-theme"
      @on-cell-click="onCellClick"
    >
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'handleStatus'" class="text-nowrap">
          <span>{{ eventHandleFormat(props.row.handleStatus) }}</span>
        </span>

        <span v-if="props.column.field === 'eventType'" class="text-nowrap">
          <span>{{ eventTypeFormat(props.row.eventType) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">
            <feather-icon icon="VoicemailIcon" class="mr-50"/>
            <span class="align-middle">查看</span>
          </b-button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props"
      >
        <div class="tCenter">
            <b-pagination
                v-model="queryParams.currentPage"
              :total-rows="queryParams.total"
              :per-page="queryParams.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="currentPageHandleChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </template>
    </vue-good-table>
    <b-modal
      id="modal-relation"
      centered
      ok-only
      ok-title="确定"
      @ok="sureSubmit"
    >
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,BCard,BButton,BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'
export default {
  components: {
    BCard,VueGoodTable,BAvatar,BPagination,BFormGroup,BFormInput,BFormSelect,BRow,BCol,BButton,
    vSelect,BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: null,
        handleStatus: '',
        deviceId: '',
        eventTypes: []
      },
      dir: false,
      roleFilter:null,
      columns: [
        {
          label: '事件ID',
          field: 'id',
        },
        {
          label: '事件名称',
          field: 'eventType',
        },
        {
          label: '时间',
          field: 'triggerTime',
        },
        {
          label: '设备ID',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        /*        {
                  label: '关联机构',
                  field: 'tenantNameSnapshot',
                },*/
        {
          label: '标签',
          field: 'tagSnapshot',
        },
        {
          label: '分组',
          field: 'tenantGroupSnapshot',
        },
        /*        {
                  label: '处理状态',
                  field: 'handleStatus',
                },*/
        {
          label: '操作',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      eventHandleStatusOptions: [
        {
          value: 0,
          label: '无需处理'
        }
      ],
      eventLvValue: 3,
      eventTypeOptions: [
        {
          value: 0,
          label: '未知事件'
        }
      ],

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTableList();
    this.eventTypeOptionsFormat();
  },
  methods: {
    advanceSearch(val) {
      this.searchTerm = val
    },
    onCellClick(params) {
      if (params.column.field == 'action') {
        this.$router.push({ name: 'event-recordInfo', query: { id: params.row.id } })
      }
    },
    sureSubmit(){
      console.log('ok')
    },

    getTableList() {
      request.get('tob/bEventRecord/pageEntryRecordsByNOTIFY', {
        pageSize: this.queryParams.pageSize,
        currentPage: this.queryParams.currentPage,
        deviceId: this.queryParams.deviceId,
        eventTypes: this.queryParams.eventTypes,
      })
          .then(res => {
            if (res.data.success) {
              this.rows = res.data.data.data
              this.queryParams.total = res.data.data.count
            } else {
              that.$refs.loginForm.setErrors(res.data.msg)
            }
          })
    },

    currentPageHandleChange(currentPage){
      this.queryParams.currentPage = currentPage
      this.getTableList()
    },

    //格式化后端返回的事件类型枚举
    eventTypeOptionsFormat() {
      request.get('tob/bEventRecord/getEnumsByEventLv', {
        eventLvValue: this.eventLvValue,
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.eventTypeOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.eventTypeOptions.push(enumObject);
          }
        }
      })
    },

    //格式化后端返回的事件处理状态枚举
    eventHandleStatusOptionsFormat() {
      request.get('tob/bEventRecord/getEventHandleStatusEnum', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.eventHandleStatusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.eventHandleStatusOptions.push(enumObject);
          }
        }
      })
    },

    //事件类型format
    eventTypeFormat(value) {
      if (value != undefined) {
        for (let item of this.eventTypeOptions) {
          if (item.value === value) {
            return item.label;
          }
        }
      }
      return value;
    },

    //事件处理状态format
    eventHandleFormat(value) {
      if (value != undefined) {
        for (let item of this.eventHandleStatusOptions) {
          if (item.value === value) {
            return item.label;
          }
        }
      }
      return value;
    },

    searchButtonClick() {
      this.queryParams.currentPage = 1
      this.getTableList()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
